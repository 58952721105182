
import Vue from 'vue';

export default Vue.extend({
  name: 'publisherLogos',
  data: (): {
    data: any; // eslint-disable-line
    image: File | null;
    prefixes: string[];
    loading: boolean;
    name: string;
    aliases: string[];
    headers: Array<object>;
    search: string;
    editDialog: boolean;
    currentItem: any; // eslint-disable-line
    imageUrl: string;
  } => ({
    data: [],
    image: null,
    prefixes: [],
    loading: true,
    name: '',
    aliases: [],
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Logo',
        value: 'imageUrl',
        sortable: false,
      },
      {
        text: 'Aliases',
        value: 'aliases',
        sortable: false,
      },
      {
        text: 'Prefixes',
        value: 'prefixes',
        sortable: false,
      },
      {
        text: '',
        value: 'edit',
        sortable: false,
      },
      {
        text: '',
        value: 'remove',
        sortable: false,
      },
    ],
    search: '',
    editDialog: false,
    currentItem: {
      prefixes: [],
      aliases: [],
    },
    imageUrl: '',
  }),
  mounted() {
    this.getPublisherLogos();
  },
  computed: {
    submitDisabled() {
      return !this.imageUrl;
    },
  },
  methods: {
    async uploadImage() {
      if (this.image) {
        try {
          const response = await this.$store.dispatch('linearAdmin/uploadPublisherLogo', this.image);
          this.imageUrl = response.url;
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
    },
    async submitData() {
      const payload = {
        name: this.name,
        prefixes: this.prefixes,
        aliases: this.aliases,
        imageUrl: this.imageUrl,
      };
      try {
        if (this.imageUrl) {
          const response = await this.$store.dispatch('linearAdmin/createPublisherLogo', payload);
          if (response.error) {
            this.$store.dispatch('showError', {
              message: response.error,
              errorType: 'error',
            });
          } else {
            this.$store.dispatch('showError', {
              message: 'Data submitted successfully',
              errorType: 'success',
            });
            this.getPublisherLogos();
          }
        }
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    },
    async getPublisherLogos(): Promise<void> {
      this.loading = true;

      await this.$store
        .dispatch('linearAdmin/getPublisherLogos')
        .then(response => {
          if (!response) {
            console.log('getPublisherLogos bad response', response);
            return;
          }
          this.data = response;
          this.loading = false;
        })
        .catch((error: Error) => {
          this.loading = false;
          console.error('getPublisherLogos error', error);
          return;
        });
    },
    openEditDialog(item) {
      if (item) {
        this.currentItem = Object.assign({}, item);
        this.originalPrefixes = [...this.currentItem.prefixes];
        this.originalAliases = [...this.currentItem.aliases];
        this.name = this.currentItem.name;
        this.editDialog = true;
      } else {
        console.error('Item is null, cannot open edit dialog');
      }
    },
    closeEditDialog() {
      this.editDialog = false;
      this.imageUrl = '';
      this.getPublisherLogos();
    },
    handlePrefixChange(newPrefixes) {
      const added = newPrefixes.filter(p => !this.originalPrefixes.includes(p));
      const removed = this.originalPrefixes.filter(p => !newPrefixes.includes(p));

      added.forEach(prefix => this.addPrefix(prefix));
      removed.forEach(prefix => this.removePrefix(prefix));

      this.originalPrefixes = [...newPrefixes];
    },
    handleAliasChange(newAliases) {
      const added = newAliases.filter(a => !this.originalAliases.includes(a));
      const removed = this.originalAliases.filter(a => !newAliases.includes(a));

      added.forEach(alias => this.addAlias(alias));
      removed.forEach(alias => this.removeAlias(alias));

      this.originalAliases = [...newAliases];
    },
    async addPrefix(prefix) {
      const payload = {
        logoName: this.currentItem.name,
        prefix,
      };

      await this.$store
        .dispatch('linearAdmin/addPublisherLogoPrefix', payload)
        .then(response => {
          if (!response) {
            console.log('addPublisherLogoPrefix bad response', response);
            return;
          }
        })
        .catch((error: Error) => {
          console.error('addPublisherLogoPrefix error', error);
          return;
        });
    },
    async removePrefix(prefix) {
      const payload = {
        logoName: this.currentItem.name,
        prefix,
      };

      await this.$store
        .dispatch('linearAdmin/removePublisherLogoPrefix', payload)
        .then(response => {
          if (!response) {
            console.log('removePublisherLogoPrefix bad response', response);
            return;
          }
        })
        .catch((error: Error) => {
          console.error('removePublisherLogoPrefix error', error);
          return;
        });
    },
    async addAlias(alias) {
      const payload = {
        logoName: this.currentItem.name,
        alias,
      };

      await this.$store
        .dispatch('linearAdmin/addPublisherLogoAlias', payload)
        .then(response => {
          if (!response) {
            console.log('addPublisherLogoAlias bad response', response);
            return;
          }
        })
        .catch((error: Error) => {
          console.error('addPublisherLogoAlias error', error);
          return;
        });
    },
    async removeAlias(alias) {
      const payload = {
        logoName: this.currentItem.name,
        alias,
      };

      await this.$store
        .dispatch('linearAdmin/removePublisherLogoAlias', payload)
        .then(response => {
          if (!response) {
            console.log('removePublisherLogoAlias bad response', response);
            return;
          }
        })
        .catch((error: Error) => {
          console.error('removePublisherLogoAlias error', error);
          return;
        });
    },
    async removePublisherLogo(item) {
      const payload = {
        logoName: item.name,
      };

      await this.$store
        .dispatch('linearAdmin/removePublisherLogo', payload)
        .then(response => {
          if (!response) {
            console.log('removePublisherLogo bad response', response);
            return;
          }
          this.getPublisherLogos();
        })
        .catch((error: Error) => {
          console.error('removePublisherLogo error', error);
          return;
        });
    },
    async updatePublisherLogoUrl() {
      const payload = {
        logoName: this.currentItem.name,
        newUrl: this.imageUrl,
      };

      await this.$store
        .dispatch('linearAdmin/updatePublisherLogoUrl', payload)
        .then(response => {
          if (!response) {
            console.log('updatePublisherLogoUrl bad response', response);
            return;
          }
          this.getPublisherLogos();
          this.image = null;
        })
        .catch((error: Error) => {
          console.error('updatePublisherLogoUrl error', error);
          return;
        });
    },
    clearImage() {
      this.imageUrl = '';
    },
    async updatePublisherLogoName() {
      const payload = {
        logoName: this.currentItem.name,
        newLogoName: this.name,
      };
      await this.$store
        .dispatch('linearAdmin/updatePublisherLogoName', payload)
        .then(response => {
          if (!response) {
            console.log('updatePublisherLogoName bad response', response);
            return;
          }
          this.getPublisherLogos();
          this.image = null;
        })
        .catch((error: Error) => {
          console.error('updatePublisherLogoName error', error);
          return;
        });
    },
  },
});
