
import Vue from 'vue';
import utils from '../../util';
import { zonedTimeToUtc } from 'date-fns-tz';
import debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'automatedAlertsLog',
  data: (): {
    data: any; // eslint-disable-line
    search: string;
    loading: boolean;
    page: number;
    limit: number;
    totalLogs: number;
    totalPages: number;
    sortBy: string;
    sortDesc: boolean;
    headers: Array<object>;
  } => ({
    data: [],
    search: '',
    loading: true,
    page: 1,
    limit: 15,
    totalLogs: 0,
    totalPages: 0,
    sortBy: '',
    sortDesc: false,
    headers: [
      {
        text: 'Result (Sent or Error)',
        value: 'result',
        sortable: false,
      },
      {
        text: 'Agency',
        value: 'agencyName',
        sortable: false,
      },
      {
        text: 'Advertiser',
        value: 'advertiserName',
        sortable: false,
      },
      {
        text: 'Campaign Name',
        value: 'campaignName',
        sortable: false,
      },
      {
        text: 'Recipient Name',
        value: 'recipientName',
        sortable: false,
      },
      {
        text: 'Recipient Email Address',
        value: 'recipientEmail',
        sortable: false,
      },
      {
        text: 'Date/Time',
        value: 'dateTime',
        sortable: false,
      },
    ],
  }),
  watch: {
    search() {
      this.debouncedSearch();
    },
  },
  mounted() {
    this.getAutomatedAlertsLog(this.limit, 0, this.search);
  },
  created() {
    // Create a debounced version of the search function with a longer delay
    this.debouncedSearch = debounce(this.fetchData, 500);
  },
  methods: {
    async getAutomatedAlertsLog(limit: number, offset: number, search: string): Promise<void> {
      this.loading = true;
      await this.$store
        .dispatch('getAutomatedAlertsLog', { limit, offset, search })
        .then(response => {
          if (!response) {
            console.log('getAutomatedAlertsLog bad response', response);
            return;
          }
          this.totalLogs = response.paginationInfo.totalObjects;
          this.totalPages = response.paginationInfo.totalPages;
          this.page = response.paginationInfo.currentPage + 1;

          this.data = response.logs.map(item => {
            item.dateTime = zonedTimeToUtc(utils.normalizeDate(item.sendTime), 'UTC');
            return item;
          });

          this.loading = false;
        })
        .catch((error: Error) => {
          this.loading = false;
          console.error('getAutomatedAlertsLog error', error);
          return;
        })
        .finally(() => {
          this.pageChangeInProgress = false; // Reset the flag after the request completes
        });
    },
    fetchData() {
      if (this.pageChangeInProgress) return;
      this.pageChangeInProgress = true;
      const orderBy = this.buildOrderBy();
      this.getAutomatedAlertsLog(this.limit, 0, this.search);
    },
    buildOrderBy() {
      if (!this.sortBy) return '';
      const direction = this.sortDesc ? 'desc' : 'asc';
      switch (this.sortBy) {
        case 'status':
          return `status_${direction}`;
        case 'agencyName':
          return `agency_name_${direction}`;
        case 'advertiserName':
          return `advertiser_name_${direction}`;
        case 'campaignName':
          return `campaign_name_${direction}`;
        case 'recipientName':
          return `recipient_name_${direction}`;
        case 'sendTime':
          return `send_date_${direction}`;
        case 'recipientEmail':
          return `recipient_email_${direction}`;
        default:
          return '';
      }
    },
    debouncedSearch() {
      this.page = 1;
      this.fetchData();
    },
    handleInputBlur() {
      this.debouncedSearch.cancel();
      this.fetchData();
    },
    onSortChange() {
      this.fetchData();
    },
    redirectToReport(item: any) {
      this.$router.push(`/reportsadmin/report?reportId=${item.reportId}`);
    },
    onPageChange(newPage) {
      if (this.pageChangeInProgress) return; // Prevent double execution

      this.pageChangeInProgress = true; // Set the flag to prevent further execution

      const currentPage = newPage.page;
      const itemsPerPage = newPage.itemsPerPage;

      if (typeof currentPage === 'number') {
        this.page = currentPage;
        this.limit = itemsPerPage;
        const offset = (currentPage - 1) * this.limit;
        const orderBy = this.buildOrderBy();
        this.getAutomatedAlertsLog(this.limit, offset, this.search);
      }
    },
    goToCampaign(item): void {
      const tactic = item.campaignType;
      let tab;
      switch (tactic) {
        case 'VIDEO':
          tab = 'gtvideo';
          break;
        case 'SITEIMPACT':
          tab = 'emailsi';
          break;
        default:
          tab = tactic ? `${tactic.toLowerCase()}` : '';
          break;
      }
      this.$router.push({
        path: '/home',
        query: {
          id: item.advertiserId,
          tab: tab,
          viewCampaigns: item.campaignId,
        }
      });
    },
  },
});
